@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap');

#faq {
    background-color: #101010;
    /* margin: 0 242.5px; */
    /* margin: 0 12.6%; */
    /* margin: 0 8%; */
    font-family: "Plus Jakarta Sans";
    padding-top: 12.5vw;
    padding-bottom: 8.333vw;
}

#faq h2 {
    font-size: 40px;
    font-weight: 600;
    margin-bottom: 40px;
}

.faq-header {
    text-align: center;
    /* margin-top: 200px; */
    margin-bottom: 120px;
    /* padding-top: 200px; */
    color: #FFFAFA;
}

.faq-header h1 {
    /* font-size: 64px; */
    font-size: 50px;
    font-weight: 600;
}

.question-section {
    color: #FFFAFA;
    margin-bottom: 200px;
}

.sponsorFaq {
    margin-bottom: 270px;
}

.dropdown-join-container {
    display: flex;
}

.dropdown-ctr {
    border-bottom: 1px solid #A9A9A9;
    /* width: 740px; */
    /* width: 39%; */
    width: 51%;

    /* margin-right: 347px; */
    margin-right: 18%;

    margin-bottom: auto;
}

/* FAQ accordion */
.accordion {
    background-color: transparent;
    color: #FFFAFA;
    font-size: 20px;
    cursor: pointer;
    padding: 24px 0;
    width: 100%;
    text-align: left;
    border: none;
    outline: none;
    transition: 0.4s;
    border-top: 1px solid #A9A9A9;
    display: flex;
}

.arrow {
    margin-left: 18px;
    width: 24px;
    transition: transform 0.4s;
}

.arrowContainer {
    margin: auto 18px auto auto;
}

.rotate {
    transform: rotate(-180deg);
}

.panel {
    color: #A9A9A9;
    font-size: 20px;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.4s ease-out;
}

.panel-text {
    margin-right: 18px;
    border-left: 2px solid #FF2B06;
    padding-left: 52px;
    margin-bottom: 24px;
}

.panel-text a {
    color: #A9A9A9;
}

.panel-text p {
    font-weight: 400;
}


/* Join our team */
.faq-join {
    width: 347px;
    /* width: 19%; */
    margin-left: auto;
    margin-bottom: auto;
}

.faq-join-descr {
    border-top: 1px solid #A9A9A9;
    border-bottom: 1px solid #A9A9A9;
    /* padding: 24px 0; */
    padding-top: 24px;
    padding-bottom: 12px;
    margin-bottom: 24px;
}

.faq-join-descr h3 {
    color: #FFFAFA;
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 20px;
}

.faq-join-descr p {
    color: #A9A9A9;
    font-size: 20px;
    font-weight: 400;
}

.faq-join-button {
    float: right;
}


/* Become a Speaker */
.faq-speaker {
    width: 347px;
    /* width: 19%; */
    margin-left: auto;
    margin-bottom: auto;
}

.faq-speaker-descr {
    border-top: 1px solid #A9A9A9;
    border-bottom: 1px solid #A9A9A9;
    /* padding: 24px 0; */
    padding-top: 24px;
    padding-bottom: 12px;
    margin-bottom: 24px;
}

.faq-speaker-descr h3 {
    color: #FFFAFA;
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 20px;
}

.faq-speaker-descr p {
    color: #A9A9A9;
    font-size: 20px;
    font-weight: 400;
}

.faq-speaker-button {
    display: flex;
    float: right;
}

.nominateButton {
    margin-left: 24px;

    /* take this line out when nomination page is open */
    display: none; 
}


/* tablet */
@media only screen and (max-width: 1024px) {
    .hide-faq-title {
        display: none;
    }

    .genFaq {
        /* padding-top: 131px; */
        padding-top: 200px;
    }

    #faq h2 {
        font-size: 32px;
        margin-bottom: 16px;
    }

    .question-section {
        margin-bottom: 0;
    }
    
    .dropdown-join-container {
        flex-direction: column;
    }

    .dropdown-ctr {
        width: 100%;
        margin-bottom: 0;
    }

    .accordion {
        font-size: 16px;
        padding: 12px 0;
    }

    .arrow {
        width: 24px;
    }

    .panel {
        font-size: 16px;
    }
    
    .panel-text {
        margin-right: 18px;
        padding-left: 24px;
        margin-bottom: 12px;
    }

    .faq-join {
        width: 100%;
        margin-top: 60px;
        margin-bottom: 160px;
    }

    .faq-join-descr {
        border: none;
        padding: 0;
    }
    
    .faq-join-descr h3 {
        font-size: 20px;
        margin-bottom: 8px;
    }
    
    .faq-join-descr p {
        font-size: 16px;
    }

    .faq-join-button {
        float: none;
        text-align: center;
    }

    .faq-speaker {
        width: 100%;
        margin-top: 60px;
        margin-bottom: 160px;
    }

    .faq-speaker-descr {
        border: none;
        padding: 0;
    }
    
    .faq-speaker-descr h3 {
        font-size: 20px;
        margin-bottom: 8px;
    }
    
    .faq-speaker-descr p {
        font-size: 16px;
    }

    .faq-speaker-button {
        float: none;
        justify-content: center;
    }

    .nominateButton {
        margin-left: 20px;
    }

    .sponsorFaq {
        margin-bottom: 110px;
    }
}


/* mobile */
@media only screen and (max-width: 450px) {
    .genFaq {
        /* padding-top: 124px; */
        padding-top: 200px;
    }

    #faq h2 {
        font-size: 24px;
        margin-bottom: 12px;
    }

    .accordion {
        font-size: 14px;
        padding: 12px 0;
    }

    .arrow {
        width: 19px;
    }

    .panel {
        font-size: 14px;
    }
    
    .panel-text {
        margin-right: 18px;
        padding-left: 16px;
        margin-bottom: 12px;
    }

    .faq-join {
        margin-top: 40px;
        margin-bottom: 100px;
    }
    
    .faq-join-descr h3 {
        font-size: 16px;
        margin-bottom: 8px;
    }
    
    .faq-join-descr p {
        font-size: 14px;
    }

    .faq-join-button {
        float: left;
    }

    .faq-speaker {
        margin-top: 40px;
        margin-bottom: 100px;
    }
    
    .faq-speaker-descr h3 {
        font-size: 16px;
        margin-bottom: 8px;
    }
    
    .faq-speaker-descr p {
        font-size: 14px;
    }

    .faq-speaker-button {
        float: left;
    }

    .applyButton {
        margin-right: 20px;
    }

    .sponsorFaq {
        margin-bottom: 124px;
    }
 }