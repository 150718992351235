#footer {
  justify-content: space-between;
  align-items: flex-start;
  background: linear-gradient(0deg, #101010 -10.3%, rgba(16, 16, 16, 0.00) 80.52%);
  mix-blend-mode: hard-light;
  margin: 0vw -12.5vw;
  padding: 1.667vw 5.208vw;
}

.testing {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

#footer-logo {
  max-width: 22rem;
  height: auto;
  flex-shrink: 0;
}

#pages {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;

  /* Regular 20 */
  font-family: Poppins;
  font-style: normal;
  line-height: normal;
  margin: 0;
  gap: 2rem;
}

.footer-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.footer-col {
  display: flex;
  flex-direction: column;
}

#pages a {
  display: flex;
  padding: 0.625rem;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 3rem;

  color: #FFFAFA;
  font-family: "Plus Jakarta Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

#pages .footer-row {
  gap: 2rem;
}

.section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0.5rem;
}

.section a {
  text-decoration: none;
  color: var(--White, #FFF);

  /* Regular 20 */
  font-family: Poppins;
  font-style: normal;
  line-height: normal;
}

#copyright {
  color: #FFFAFA;
  font-family: "Plus Jakarta Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0rem;
}

#icons {
  display: flex;
  gap: 1.5rem;
  margin-right: 0.7rem;
}

#icons a {
  display: flex;
  float: right;
  align-items: center;
  background-color: transparent;
}

#icons a:hover {
  opacity: 50%;
}

#pages a:hover {
  opacity: 50%;
}

/* Responsive CSS for tablets: */
@media screen and (max-width: 1024px) {
  #footer {
    align-items: flex-start;
    gap: 2rem;
  }
  #top, #bottom {
    display: block !important;
    padding-bottom: 2rem !important;
  }
  #pages {
    padding-top: 2rem;
  }
  #pages .footer-col {
    flex-direction: row;
  }
  #pages .footer-row {
    gap: 0rem;
  }
  #pages a {
    padding: 0rem;
    padding-right: 0.625rem;;
    gap: 1.5rem;
  }
  #copyright {
    order: -1 !important;
    font-size: 1rem;
  }
  .mobile-only {
    display: visible;
    margin-bottom: 0;
    font-size: 0.75rem;
  }
  #footer-logo {
    max-width: 11rem;
  }
}

@media screen and (min-width: 450px) {
  .mobile-only {
      display: none;
  }
}

#top {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  align-self: stretch;
  flex-wrap: wrap;
  padding-bottom: 6rem;
}

#bottom {
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-self: stretch;
  flex-wrap: wrap;
}