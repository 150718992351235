#event-card {
  display: flex;
  flex-direction: row;
  width: 1469px;
  align-items: center;
  font-family: "Plus Jakarta Sans";
  align-self: center;
}

.event-img {
  width: 53.061rem;
  height: 29.843rem;
  flex-shrink: 0;
  object-fit: cover;
}

#event-info {
  display: flex;
  flex-direction: column;
  margin-left: 50px;
  /* align-self: flex-start; */
}

#event-name {
  margin-bottom: 0px;
  margin-top: 7px;
  font-size: 2.5rem;
}

#event-hr {
  margin-top: 9px;
  margin-bottom: 9px;
  opacity: 100%;
}

#event-details {
  margin-top: 5px;
  font-weight: lighter;
  font-size: 1.25rem;
}

#event-description {
  font-size: 1.25rem;
}

#event-description, #event-details, #event-name, #event-info button {
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #FFFAFA;
}

#event-header {
  display: flex;
  flex-direction: row;
  gap: 12px;
  font-size: 20px;
  align-items: center;
}

#event-header p {
  margin-top: auto;
  margin-bottom: auto;
}


@media only screen and (max-width: 1530px) {
  .event-img {
    width: 60%;
    height: 30%;
  }

  #event-card {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
}

@media only screen and (max-width: 1024px) {
  #event-card {
    display: flex;
    flex-direction: column;
    gap: 3rem;
  }

  .event-img {
    width: 100%;
  }

  #event-info {
    margin-left: 0px;
  }
}

@media only screen and (max-width: 575px) {
  #event-description, #event-details {
    font-size: 1rem;
  }

  #event-name {
    font-size: 2rem;
  }

  #event-header img {
    width: 2.17944rem;
    height: 1.45294rem;
  }
}

@media only screen and (max-width: 375px) {
  #event-description, #event-details {
    font-size: 0.875rem;
  }

  #event-name {
    font-size: 1.5rem;
  }

  #event-header img {
    width: 1.25rem;
    height: 0.83331rem;
  }
}