#dropdown-basic {
    display: flex;
    /* width: 330px; */
    padding: 12px 36px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background-color: #101010;;
    border-radius: 0;
    border: 1px solid #FFE9E9;

    color: #FFF;
    text-align: center;
    font-family: "Plus Jakarta Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    gap: 2.125rem;
}

/* Basic styling for the Dropdown component */

  #dropdown-menu {
    width: 100%;
    max-height: 200px;
    overflow-y: scroll;
    overflow-x: hidden;
    background-color: #101010;
    border: 1px solid #FFE9E9;
  }

  /* WebKit Browsers */
#dropdown-menu::-webkit-scrollbar {
    width: 8px;
  }

  #dropdown-menu::-webkit-scrollbar-track {
    background: #2e2e2e;
  }

  #dropdown-menu::-webkit-scrollbar-thumb {
    background-color: #4e4e4e;
    border-radius: 4px;
    border: 2px solid #2e2e2e;
  }

  #dropdown-menu::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  #dropdown-item {
    background: #292727;
    color: #FFF;
    font-family: "Plus Jakarta Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 12px 36px;
    border-bottom: 1px solid rgba(255, 233, 233, 0.30);


  }

  #dropdown-item:hover {
    background-color: #641A15;
  }

  #dropdown-basic::after {
    display: none;
  }

@media only screen and (max-width: 1024px) {
    #dropdown-basic {
        padding: 5px 8px;
        gap: 11px;
        font-size: 16px;
        
    }
    /* .dropdown-arrow {
      width: 6vw;
    } */
}

@media only screen and (max-width: 450px) {
  #dropdown-basic {
      padding: 5px 8px;
      gap: 11px;
      font-size: 16px;
      
  }
  .dropdown-arrow {
    width: 6vw;
  }
}