@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap');
#about {
  padding-top: 10rem;
}

#about p {
  color: #A9A9A9;
}

.hide-desktop {
  display: none;
}

#header {
  color: #FFFAFA;
  text-align: center;
  font-family: "Plus Jakarta Sans";
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-bottom: 7.5rem;
}

.location {
  display: flex;
  align-items: flex-start;
  gap: 0.75rem;
  align-self: stretch;
  color: #A9A9A9;
  font-family: "Plus Jakarta Sans";
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.bar {
  border-top: 1px solid #A9A9A9;
}

.buttons {
  display: flex;
  align-items: flex-start;
  gap: 2.5rem;
}

.about-logo {
  max-width: 100%;
  height: auto;
  padding-bottom: 1.5rem;
}

#tedxuofw, #ro {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

#tedx, #ted {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* gap: 1.5rem; */
  align-self: stretch;
  display: flex;
  /* padding-top: 12.5rem; */
}
#ro {
  padding-top: 12.5rem;
  padding-bottom: 10rem;
}

.white {
  color: white;
}

.about-row {
  display: flex;
  gap: 12.5rem;
}

.carousel img, #tedx img, #ted img{
  padding-top: 1rem;
  padding-bottom: 1rem;
}

#ro .bar {
  padding-top: 1rem;
}

#ro img {
  max-width: 38.25rem;
  height: auto;
}

#tedxuofw {
  gap: 3rem;
}

.carousel {
  padding-top: 2rem;
}

.uofw-about p {
  color: #A9A9A9;
  font-size: 20px;
  font-weight: 400;
}

.uofw-about-img p {
  color: #A9A9A9;
  text-align: center;
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
}

.tedxuofw {
  border-top: 1px solid #A9A9A9;
  padding-top: 32px;
  margin-bottom: 200px;
  display: flex;
}

.uofw-info {
  margin-right: auto;
  /* width: 725px; */
  width: 35vw;
}

.uofw-info img {
  width: 25vw;
}

.uofw-info p {
  margin: 24px 0;
  color: #A9A9A9;
  font-size: 20px;
  font-weight: 400; 
}

.uofw-buttons {
  display: flex;
}

.uofw-join-button {
  margin-right: 24px;
}

.uofw-img {
  margin: auto 0;
}

.uofw-img img {
  /* width: 612px; */
  width: 30vw;
}

.uofw-img p {
  color: #A9A9A9;
  text-align: center;
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
  margin-top: 20px;
}

/* Responsive CSS for tablets: */
@media screen and (max-width: 1024px) {
  .hide-tablet {
    display: none;
  }

  .hide-desktop {
    display: block;
  }

  #about p {
    font-size: 16px;
  }

  .about-row, #tedxuofw {
    display: block !important;
  }
  .about-logo {
    padding-top: 2rem;
  }
  .carousel img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 95%;
    margin-top: 3.75rem;
  }
  #header {
    text-align: left;
    padding-bottom: 2rem;
  }
  #tedx, #ted, #ro .about-row .about-col {
    padding-top: 100px;
  }
  #ro {
    padding-top: 4rem;
    padding-bottom: 4rem;
    display: grid !important;
    gap: 2rem;
  }
  #ro .about-row {
    display: grid !important;
    flex-direction: column;
    gap: 2rem !important;
  }
  #ro-img {
    order: -1;
  }
  #ro img {
    /* remove rounded corners for mobile screen */
    /* border-radius: 1rem; */
    max-width: 100%;
  }

  .tedxuofw {
    flex-direction: column;
  }
  
  .uofw-info {
    width: fit-content;
    margin-right: 0;
  }
  
  .uofw-info img {
    width: fit-content;
  }
  
  .uofw-info p {
    margin: 28px 0;
    font-size: 16px;
  }
  
  .uofw-buttons {
    display: flex;
  }
  
  .uofw-join-button {
    margin-right: 12px;
  }

  .uofw-img {
    margin-top: 60px;
    text-align: center;
  }
  
  .uofw-img img {
    width: fit-content;
    /* width: 95vw; */
    margin-left: 0;
    margin-right: 0;
  }
  
  .uofw-img p {
    text-align: center;
    font-size: 16px;
    margin-top: 10px;
  }

  .buttons {
    gap: 24px;
  }
}  

@media only screen and (max-width: 450px) {
  #about p {
    font-size: 14px;
  }

  .buttons {
    gap: 20px;
  }

  #tedx, #ted, #ro .about-row .about-col {
    padding-top: 80px;
  }
}