#buttons button {
    padding: 12px 24px;
    background-color: transparent;
    color: #FFFAFA;
    border: 1px solid #FFFAFA;
    font-size: 16px;
    font-weight: 400;
    transition: .5s;
}

#buttons button:hover {
    background-color: #FFFAFA;
    color: #101010;
    transition: .4s;
}

/* tablet */
@media only screen and (max-width: 575px) {
    #buttons button {
        font-size: 12px;
    }
}

/* mobile */
@media only screen and (max-width: 375px) {
    #buttons button {
        font-size: 12px;
    }
}