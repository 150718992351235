#navbar {
  width: 100vw;
  display: flex;
  justify-content: center;
  background: linear-gradient(180deg, #101010 27.24%, rgba(16, 16, 16, 0.00) 87.15%);
  position: fixed;
  overflow: hidden;
  transition: top 0.3s;
  z-index: 2;
  margin-left: -11.719vw;
}

#nav-content {
  display: flex;
  width: 100%;
  padding: 5rem 6.25rem 5rem 6.25rem;
  justify-content: space-between;
  align-items: center;
}

#logo {
  padding: 5rem 6.25rem 5rem 6.25rem;

}

#nav-left img {
  width: 12.32706rem;
  flex-shrink: 0;
}

#nav-right {
  display: flex;
  flex-direction: row;
  align-items: center;
}

#nav-right a {
  color: #FFFAFA;
  text-align: center;
  font-family: "Plus Jakarta Sans";
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
  margin-left: 3.75rem;
}

#nav-right a:hover {
  color: #FF2B06;
  transition: 0.5s;
}

#small-screen {
  display: none;
}

#side-menu-icon {
  display: none;
}

#side-menu-icon-clicked {
  display: none;
}

#half-bg {
  display: none;
}

#exit-button {
  display: none;
}

@media only screen and (max-width: 1130px) {
  #nav-right a {
    margin-left: 2.4rem;
  }
}

@media only screen and (max-width: 1024px) {
  #side-menu-icon {
    display: block;
    float: right;
    z-index: 3;
  }

  #side-menu-icon-clicked {
    display: none;
  }

  #nav-right {
    display: none;
  }

  #nav-right-side {
    display: flex;
    flex-direction: column-reverse;
    z-index: 1;
    margin-left: 23%;
  }

  #nav-right-side a {
    color: #FFFAFA;
    font-family: "Plus Jakarta Sans";
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration: none;
    padding-bottom: 1.3rem;
    padding-top: 1.3rem;
  }

  #navbar {
    margin-left: -7rem;
    margin-right: -2rem;
  }

  #navbar-with-side-menu {
    width: 100vw;
    display: flex;
    position: fixed;
    overflow: hidden;
    transition: top 0.3s;
    z-index: 2;
    margin-left: -2rem;
    margin-right: -2rem;
  }

  #nav-content-side {
    display: flex;
    width: 100%;
    padding: 2rem 0rem 6.25rem 3rem;
  }

  #nav-content {
    padding: 3rem 0rem 6.25rem 0rem;
    width: 86%;;
  }

  #nav-left img {
    height: 2.86275rem;
  }

  #half-bg-side {
    width: 30%;
    height: 100vh;
    background: linear-gradient(180deg, #101010 27.24%, rgba(16, 16, 16, 0.00) 87.15%);
    position: fixed;
    padding: 30%;
    right: 0;
    top: 0;
  }

  #half-bg-other-side {
    left: 0;
    top: 0;
    height: 100vh;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.34);
    padding: 30%;
    width: 100vw;
  }

  #nav-button-row {
    display: flex;
    justify-content: space-between;
    width: 44vw;
    align-items: center;
    padding-bottom: 1.3rem;
  }

  #nav-right-side #buttons button{
    font-size: 1.5rem;
  }

  #exit-btn {
    height: 24px;
  }
}

@media only screen and (max-width: 830px) {
  #nav-right-side {
    margin-left: 17%;
  }
}

@media only screen and (max-width: 700px) {
  #nav-right-side {
    margin-left: 12%;
  }

  #nav-left img {
    width: 90%;
  }

  #half-bg-side {
    padding: 28%;
  }
}

@media only screen and (max-width: 580px) {
  #nav-right-side {
    margin-left: 6%;
  }
}

@media only screen and (max-width: 500px) {
  #nav-right-side {
    margin-left: 4%;
  }

  #nav-right-side a {
    font-size: 0.875rem;
  }

  #nav-right-side #buttons button{
    font-size: 0.875rem;
  }

  #half-bg-side {
    padding: 25%;
  }
}

@media only screen and (max-width: 450px) {
  #navbar {
    margin-left: -3rem;
    margin-right: -2rem;
  }
}

