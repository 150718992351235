html,
body,
#root,
.App {
    margin: 0;
    font-family: "Plus Jakarta Sans";
}

.App {
    background-size: cover;
    background-color: #101010;
    color: #FFFAFA;;
    padding-left: 11.719vw;
    padding-right: 11.719vw;
    /* padding-top: 20rem; */
    overflow-x: hidden;

}


/* @media only screen and (max-width: 1024px) {
    .App {
        padding-left: 20px;
        padding-right: 20px;
    }
}


@media only screen and (max-width: 450px) {
    .App {
        padding-left: 20px;
        padding-right: 20px;
    }
} */