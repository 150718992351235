.past-events-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-top: 12.5vw;
  padding-bottom: 8.333vw;
}

.past-events-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.past-events-hr {
  padding-bottom: 3.75rem;
}

.boxes-ctr {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}

.events-heading {
  font-size: 4rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.mobile-dropdown {
  display: none;
}

@media only screen and (max-width: 1024px) {

  .boxes-ctr {
    justify-content: center;
  }

  .past-events-container {
    flex-direction: column;
  }

  .events-heading {
    font-size: 32px;
  }

  .desktop-dropdown {
    display: flex;
    padding-bottom: 24px;
  }

  .events-heading {
    padding-bottom: 8px;
  }

  .past-events-hr {
    padding-bottom: 24px;
    margin-top: 0rem;
    margin-bottom: 0rem;
  }
}

@media only screen and (max-width: 450px) {
  .mobile-dropdown {
    display: flex;
    padding-bottom: 24px;
  }

  .desktop-dropdown {
    display: none;
  }
}