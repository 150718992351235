#landing {
  color: #FFFAFA;
  font-family: "Plus Jakarta Sans";
  display: flex;
  flex-direction: column;
  padding-top: 0px;
  align-items: center
}

#tedx-intro {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-self: center;
  position: relative;
  width: 100vw;
  mix-blend-mode: exclusion;
  height: 72.25rem;
  align-items: center;
  overflow: hidden;
}

.background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
  opacity: 0.4;
}

#intro-top {
  width: 70%;
  position: relative;
  z-index: 1;
}

#intro-bottom {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 2.25rem;
}

#landing-about {
  display: flex;
  flex-direction: column;
  width: 38.6875rem;
  gap: 0.625rem;
  margin-top: 14px;
}

#intro-bottom img {
  width: 42.292vw;
}

#tedx-intro h1, .impact-number-pair h2 {
  font-size: 4rem;
}

#landing-about p {
  font-size: 1.125rem;
}

#tedx-intro h1, #landing-about p, .video-info-pair p, #team-card-right p {
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

/********************** Section below belongs to the down arrow ********************/
#down-arrow-container { 
  position: relative; /* Prevents hitbox movement */
  width: 4.2rem;
  height: 4.2rem;
  display: flex; 
  align-items: center; /* center arrow vertically */
  justify-content: center; /* center arrow horizontally */
  cursor: pointer; 
}
#down-arrow {
  width: 3.4375rem;
  height: 1.6875rem;
  align-self: center;
  transition: 0.3s;
  animation: upAndDown 1.5s infinite;
}
@keyframes upAndDown { /* Vertical moving animation */
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px); /* Adjust value to control how much it moves */
  }
}
/***********************************************************************************/

#landing-content {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 12.5rem;
  margin-top: 57px;
  margin-bottom: 12.5rem;
}

#landing-content h1 {
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

#upcoming-events, #impact, #highlighted-talks, #join-our-team {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2.5rem;
  width: 100%;
}


#impact-numbers {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 11.25rem;
}

.impact-number-pair {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2.25rem;
}

.impact-number-pair h2, p {
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.impact-number-pair p {
  font-size: 1rem;
  text-align: center;
}

#lives-impacted h2 {
  color: #FF2B06;
}

#upcoming-events {
  display: none;
}

#talk-videos {
  display: flex;
  height: 372.955px;
  align-items: flex-start;
  width: 100%;
  justify-content: space-around;
}

.video-info-pair p {
  font-size: 1.25rem;
  margin-top: 0px;
  margin-bottom: 0px;
}

.video-info-pair {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.5rem;
}

#talk-videos iframe {
  width: 460px;
  height: 18.26606rem;
}

#team-card {
  display: flex;
  max-width: 91.875rem;
  align-items: center;
  gap: 2.5rem;
}

#team-card img {
  width: 64.04331rem;
  height: 34.35113rem;
}

#team-card-right {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.25rem;
}

#team-card-right p {
  font-size: 1.5rem;
}

@media only screen and (max-width: 1530px) {

  #talk-videos iframe {
    width: 100%;
    height: 16rem;
  }

  .video-info-pair {
    width: 30%;
  }

  #team-card img {
    width: 60%;
    height: 30%;
  }

  #intro-top {
    width: 80%;
  }

  #team-card-right p {
    font-size: 1.2rem;
  }

  #landing-content {
    width: 130%;
  }
}

@media only screen and (max-width: 1024px) {
  #intro-bottom {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
  }

  #intro-bottom img {
    width: 70%;
  }

  #tedx-intro h1 {
    font-size: 3rem;
  }

  #landing-about {
    width: fit-content;
  }

  #intro-top {
    padding-top: 6.25rem;
    width: 86%;
  }

  #tedx-intro  {
    height: 50rem;
  }

  #landing-content {
    width: 98%;
    margin-bottom: 10.5rem;
  }

  #upcoming-events, #impact, #highlighted-talks, #join-our-team {
    width: 100%;
  }

  #impact-numbers {
    width: 86%;
    gap: 0rem;
  }

  #talk-videos {
    display: flex;
    flex-direction: column;
    height: fit-content;
    width: 97%;
  }

  #talk-videos iframe {
    width: 100%;
    height: 548px;
  }

  #landing-content {
    gap: 10rem;
  }

  .video-info-pair {
    width: 100%;
    height: 100%;
  }

  #team-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 97%;
    justify-content: center;
  }

  #team-card-right {
    align-items: center;
  }

  #team-card img {
    width: 100%;
  }
}

@media only screen and (max-width: 800px) {
  #intro-bottom img {
    height: 6rem;
  }

  .impact-number-pair h2 {
    font-size: 3rem;
  }

  .impact-number-pair {
    gap: 1rem;
  }
}

@media only screen and (max-width: 600px) {
  #intro-bottom img {
    width: 16.61288rem;
    height: 3.15069rem;
  }

  #tedx-intro h1 {
    font-size: 1.25rem;
  }

  #tedx-intro {
    height: 40rem;
  }

  #landing-about p {
    font-size: 1rem;
  }

  .impact-number-pair p {
    font-size: 0.875rem;
  }

  .impact-number-pair h2 {
    font-size: 2rem;
  }

  #impact-numbers {
    width: 100%;
  }

  #talk-videos iframe {
    height: 20rem;
  }

  #landing-content h1 {
    font-size: 2rem;
  }

  #landing-content {
    gap: 7.5rem;
    margin-bottom: 8.5rem;
  }

  #upcoming-events, #impact, #highlighted-talks, #join-our-team {
    gap: 2rem;
  }

  #intro-bottom {
    flex-direction: column;
    align-items: flex-start;
    gap: 8.5rem;
  }

  #intro-bottom img {
    display: none;
  }

  #intro-bottom h1 {
    margin-top: 1rem;
  }

}

@media only screen and (max-width: 375px) {
  #landing-about p, .video-info-pair p {
    font-size: 0.875rem;
  }

  #landing-content h1 {
    font-size: 1.5rem;
  }

  .impact-number-pair p {
    font-size: 0.75rem;
  }

  #team-card-right p {
    font-size: 1rem;
  }

  #landing-content {
    gap: 6.25rem;
    margin-bottom: 6.5rem;
  }

  #talk-videos iframe {
    height: 16rem;
  }

  .video-info-pair {
    gap: 0.5rem;
  }

  #upcoming-events, #impact, #highlighted-talks, #join-our-team {
    gap: 1.5rem;
  }

}