/* widths and heighs are halved rn */

.form-container {
    display: flex;
    flex-direction: column;
    width: 35.677vw;
}

.fields {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    padding-bottom: 40px;
}

.input-fields {
    display: flex;
    flex-direction: column;
    /* width: 47.313rem; */
    padding: 1.5rem 2.25rem;
    align-items: center;
    gap: 0.75rem;
    align-self: stretch;
    border: 0rem;
    color: white;
    border-radius: 0.5rem;
    background: rgba(53, 53, 53, 0.60);
    backdrop-filter: blur(1.578rem);
}

.message-box {
    height: 18.75rem;
}

form {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    align-content: stretch;
    width: 100%;
}

@media only screen and (max-width: 1024px) {
    .fields {
        gap: 8px;
        padding-bottom: 16px;
    }

    .form-container {
        width: 100%;
    }

    .input-fields {
        display: flex;
        padding: 13.29px 19.935px;
    }

    .message-box {
        height: 166.129px;
    }

    form {
        gap: 16px;

    }

    .form-container {
        padding-bottom: 80px;
        align-items: center;
    }
}